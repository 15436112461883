import { translate } from "./translate";

export const timeGreeting = (time = new Date().getHours()): string => {
  switch (true) {
    case time < 12 && time > 3:
      return translate("goodMorning");
    case time >= 12 && time < 17:
      return translate("goodAfternoon");
    case time >= 17 && time < 20:
      return translate("goodEvening");
    default:
      return translate("goodDark");
  }
};
