
import { defineComponent } from "vue";
import LastLogin from "@/components/widgets/LastLogin.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "dashboard",
  components: {
    LastLogin
  },
  setup() {
    const store = useStore();

    store.dispatch(Actions.AUTH__ME);
  }
});
