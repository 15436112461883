
import { defineComponent, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { timeGreeting } from "@/core/helpers/timeGreeting";
import { convertToFullDateAndTime } from "@/core/helpers/date-helper";

export default defineComponent({
  setup() {
    const store = useStore();
    const currentUser = computed(() => store.getters.currentUser);
    const grettingMessage = computed(
      () => `Assalamu'alaikum, ${timeGreeting()} ${currentUser.value?.name}`
    );
    const lastLogin = computed(() =>
      currentUser.value.last_login ? currentUser.value.last_login : Date.now()
    );

    onMounted(() => {
      setCurrentPageTitle(grettingMessage.value);
    });

    return { lastLogin, convertToFullDateAndTime };
  }
});
